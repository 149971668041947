@use "@causevest/ui-kit/styles";

.wrapper {
  max-width: 400px;
  margin: auto;
  background: styles.$color-white;
  border-radius: 8px;
  padding: 16px;
}

.inner {
  gap: 20px;
}

.title {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin: 0;
}

.forgotPass {
  font-size: 14px !important;

  &:hover {
    background: transparent;
  }
}

.googleBtn > button {
  margin: 0 auto;
}

.inputs {
  gap: 15px;
}

.loginBtn {
  height: 40px;
  border-radius: 20px !important;
}

.info {
  font-size: 12px;
  line-height: 14px;
  color: styles.$color-grey-1;
  text-align: center;
}