@use "@causevest/ui-kit/styles";

.inner {
  margin: auto;
  align-items: center;
  background: styles.$color-white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 30px rgba(13, 58, 115, 0.1);
  text-align: center;
}

.title {
  font-size: 18px;
  margin-top: 0;
  font-weight: 400;
}

.btn {
  width: 160px;
  height: 40px;
}