@use "@causevest/ui-kit/styles";

.main {
  gap: 30px;
}

.left__pic {
  position: relative;
}

.heading {
  justify-content: flex-start;
}

.id {
  color: styles.$color-blue;
  font-size: 14px;
  font-weight: 500;
}

.description {
  word-break: break-word;
  white-space: pre-line;
}

.daysLeft {
  text-align: right;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.status {
  margin-top: 15px;
}

.select {
  min-width: 200px;

  [role="combobox"] {
    padding: 4px 35px 4px 15px;
  }

  &._draft [role="combobox"] {
    color: styles.$color-red;
  }

  &._active [role="combobox"] {
    color: styles.$color-green;
  }
}

@media (max-width: styles.$md) {
  .main,
  .aside {
    gap: 25px;
  }

  .heading {
    justify-content: space-between;
  }

  .id {
    @include styles.textEllipsis(50%);
  }
}

@media (max-width: 850px) {
  .main {
    flex-direction: column;
    gap: 15px;
  }

  .left {
    order: 1;

    &__pic {
      display: none;
    }
  }

  .right {
    order: 2;
  }
}

@media (max-width: styles.$sm) {
  .description {
    font-size: 14px;
  }

  .daysLeft {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media (max-width: 485px) {
  .id {
    display: none;
  }

  .heading {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}
