.fundraisers {
  gap: 35px;
  flex-wrap: wrap;
  align-items: flex-start;

  &__inner {
    gap: 5px;
    min-width: 120px;
    align-items: flex-start;
  }

  &__title {
    font-size: 16px;
    white-space: nowrap;
  }

  &__person {
    gap: 5px;
  }

  &__logo {
    border-radius: 50%;
    overflow: hidden;
    min-width: 18px;
    height: 18px;
  }

  &__name {
    font-size: 12px;
    white-space: nowrap;
  }
}

.date {
  font-size: 12px;
  min-width: 125px;
  margin-top: 10px;

  &._small {
    margin-top: 0;
  }
}
