@use "@causevest/ui-kit/styles";

.wrapper {
  gap: 5px;
}

.title {
  color: styles.$color-black-6;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;

  &__asterisk {
    color: styles.$color-red;
  }
}

.description {
  color: styles.$color-grey-1;
  font-size: 12px;
  font-weight: 400;
  min-height: 144px;
}

.embeded {
  position: relative;
  width: 100%;
  height: 150px;
  margin: 0 auto;

  &__icon {
    border: 1px solid styles.$color-light-grey;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__close {
    cursor: pointer;
    color: styles.$color-red;
    position: absolute;
    top: -14px;
    right: -15px;
  }
}

.dragDrop {
  margin: auto;
  align-items: center;
  gap: 5px;
  max-width: 40%;
  text-align: center;

  &__wrapper {
    width: 100%;
    height: 150px;
    background: styles.$color-light-grey;
    border: 1px dashed styles.$color-grey-1;
    border-radius: 10px;
    cursor: pointer;

    &._error {
      border: 1px dashed styles.$color-red;
    }
  }

  &__title {
    color: styles.$color-grey-1;
    font-weight: 500;
    font-size: 12px;
  }

  &__description {
    color: styles.$color-grey-1;
    font-size: 12px;
  }
}

@media (max-width: styles.$sm) {
  .description {
    min-height: auto;
  }
}
