@use "@causevest/ui-kit/styles";

.wrapper {
  gap: 20px;
}

.person {
  gap: 10px;

  &__icon {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__name {
    white-space: nowrap;
    font-size: 12px;
  }

  &__time {
    white-space: nowrap;
    font-size: 10px;
    color: styles.$color-grey-1;
  }
}

.description {
  color: styles.$color-black-6;
  font-size: 14px;
}

.info {
  &__votes {
    gap: 5px;
  }

  &__price {
    font-size: 12px;
    font-weight: 500;
  }

  &__likes {
    font-size: 12px;
    color: styles.$color-red;
  }

  &__heart svg {
    fill: styles.$color-red;
  }

  &__votesLink {
    font-size: 12px;
    background: transparent;
    padding: 0;
    line-height: 1;

    &:hover {
      background: transparent;
    }
  }
}

@media (max-width: styles.$sm) {
  .description {
    display: none;
  }
}
