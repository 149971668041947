@use "@causevest/ui-kit/styles";

.wrapper {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 68px;
  padding: 68px 0;
}

.figureWrapper {
  width: 473px;
  height: 473px;
}

.circleOne {
  width: 473px;
  height: 473px;
  background: #f8fbfe;
  border-radius: 50%;
}

.circleTwo {
  width: 417px;
  height: 417px;
  margin: auto;
  background: #ecf4fc;
  border-radius: 50%;
}

.circleThree {
  position: relative;
  width: 363px;
  height: 363px;
  margin: auto;
  background: linear-gradient(139deg, styles.$color-blue-3 15.48%, styles.$color-blue-2 85.76%);
  border-radius: 50%;
}

.orangeBall {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 3;
  top: 6px;
  left: 56px;
  border-radius: 50%;
  opacity: 0.9018;
  border: 4px solid styles.$color-white;
  background: linear-gradient(180deg, #fad961 0%, #f76b1c 100%);
}

.bgFigure {
  margin-top: -50px;
}

.list {
  position: absolute;
  top: 18px;
  left: 65px;
  width: 235px;
  border-radius: 10px;
  border: 1px solid #cde5f9;
  background: styles.$color-white;
  box-shadow: 0 10px 40px rgba(13, 58, 115, 0.1);

  &__title {
    color: styles.$color-black-6;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 20px;
    border-bottom: 1px solid #cde5f9;
  }

  &__body {
    padding: 20px;
    height: 293px;
    gap: 15px;
  }

  &__btn {
    position: absolute;
    left: 19px;
    top: calc(100% - 20px);
    width: 196px;
    height: 40px;
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  &__item {
    gap: 12px;
  }

  &__logo {
    position: relative;
    display: flex;
    min-width: 37px;
    width: 37px;
    height: 37px;
    border: 1px solid styles.$color-blue;
    border-radius: 50%;
    overflow: hidden;

    > img {
      margin: auto;
    }
  }

  &__companyName {
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
  }

  &__amountTitle {
    color: styles.$color-grey-2;
    font-size: 10px;
    white-space: nowrap;
    margin-top: 5px;
    letter-spacing: 1px;
  }

  &__amountData {
    margin-top: 5px;
    font-size: 10px;
    white-space: nowrap;
  }
}

.create {
  gap: 20px;
  margin-top: 25px;

  &__hint {
    color: styles.$color-black-6;
    font-size: 18px;
    font-weight: 400;
  }

  &__btn {
    width: 160px;
    height: 40px;
  }
}

@media (max-width: styles.$sm) {
  .wrapper {
    padding: 35px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .figureWrapper {
    margin: 0 auto;
    width: 473px;
    height: 473px;
  }

  .create {
    &__btn {
      width: 100%;
    }
  }
}

@media (max-width: 450px) {
  .figureWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .circleOne {
    position: absolute;
    top: 0;
    left: calc(50% - 238px);
  }
}
