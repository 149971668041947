.loading {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 3px solid transparent;
  margin-top: 30px;
}

.effects {
  transition: all 0.3s ease;

  &._first {
    animation: rotate 1s ease infinite;
  }

  &._second {
    animation: rotateOpacity 1s ease infinite 0.1s;
  }

  &._third {
    border: 3px solid transparent;
    border-left: 3px solid rgba(15, 105, 220, 1);
    animation: rotateOpacity 1s ease infinite 0.2s;
    border-radius: 50%;
  }

  &._first, &._second {
    border: 3px solid transparent;
    border-left: 3px solid rgba(15, 105, 220, 1);
    border-radius: 50%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    transform: rotate(1turn);
    opacity: 1;
  }
}