@use "@causevest/ui-kit/styles";

.wrapper {
  position: relative;
  border-radius: 50%;
  border: 3px solid styles.$color-white;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  background: styles.$color-white;
  padding: 2px;
  border-radius: 50%;
  height: 22px;

  &._big {
    height: 26px;
  }
}
