@use "@causevest/ui-kit/styles";

.timing {
  color: styles.$color-black-6;
  font-size: 12px;
  font-weight: 400;

  &__icon {
    height: 12px;
  }
}