@use "@causevest/ui-kit/styles";

.edit {
  &__wrapper {
    max-width: 652px;
    margin-top: 30px;
  }

  &__title {
    color: styles.$color-grey-7;
    font-size: 24px;
    font-weight: 400;
    margin-top: 0;
  }

  &__form {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px 0;
    margin: 15px 0 30px;

    > * {
      width: 48%;

      > * {
        width: 100%;
      }
    }
  }

  &__footer {
    flex-direction: row;
    justify-content: flex-end;
    padding: 30px 15px 15px;
    border-top: 1px solid styles.$color-light-grey;
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  &__submitBtn {
    width: 145px;
    height: 35px;
  }
}

@media (max-width: styles.$md) {
  .edit {
    &__wrapper {
      max-width: 100%;
    }
  }
}

@media (max-width: styles.$sm) {
  .edit {
    &__form {
      gap: 20px 0;
      margin: 5px 0 20px;

      > * {
        width: 100%;
      }
    }

    &__footer {
      padding: 20px 15px 5px;
    }
  }
}
