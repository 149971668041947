@use "@causevest/ui-kit/styles";

.slider {
  width: calc(100% + 15px);
  margin-bottom: 30px;
}

.inner {
  padding-right: 15px;
  overflow: visible;
}

.slide {
  max-width: 70px;
}

.charity {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  border: 1px solid styles.$color-light-grey;
  overflow: hidden;

  > * {
    margin: auto;
  }
}
