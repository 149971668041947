@use "@causevest/ui-kit/styles";

.wrapper {
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  gap: 40px;
}

.title {
  font-size: 24px;
  font-weight: 300;
}

.description {
  white-space: nowrap;
}

@media (max-width: styles.$md) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}