@use "@causevest/ui-kit/styles";

.wrapper {
  gap: 15px;
  flex-wrap: wrap;
}

.select {
  width: 180px;

  [role="combobox"] {
    padding: 4px 35px 4px 15px;
  }
}

.submit {
  width: 100px;
}

@media (max-width: styles.$sm) {
  .wrapper {
    width: 100%;
    margin: auto;

    > * {
      width: 100%;
    }
  }

  .btn {
    width: 100%;
  }

  .select {
    width: 100%;
  }

  .submit {
    width: 100%;
  }
}