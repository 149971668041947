@use "@causevest/ui-kit/styles";

.title {
  color: styles.$color-black-6;
  font-size: 22px;
  font-weight: 300;
  white-space: nowrap;
}

.btn {
  width: 100%;
  height: 35px;
  margin-top: 10px;
}
