@use "@causevest/ui-kit/styles";

.trusted {
  flex-direction: row;
  gap: 35px;
  padding: 80px 0;

  &__inner {
    gap: 35px;
  }

  &__title {
    font-size: 18px;
    font-weight: 300;
    white-space: nowrap;
    margin-top: 18px;
  }

  &__img {
    width: 100px;
    height: 67px;
    border: 1px solid styles.$color-light-grey;
    border-radius: 8px;
    display: flex;

    > img {
      margin: auto;
    }
  }
}

@media (max-width: styles.$sm) {
  .trusted {
    flex-direction: column;
    padding: 20px 0 35px;

    &__inner {
      gap: 25px;
    }
  }
}
