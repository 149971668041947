@use "@causevest/ui-kit/styles";

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-transform: inherit;
  color: styles.$color-white;
  border-radius: 4px;
  background: linear-gradient(312deg, styles.$color-blue-2 -59.61%, styles.$color-blue-3 73.4%);
  box-shadow: 0 7px 20px 0 rgba(15, 105, 220, 0.2);

  &._arrowed {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }

  &._disabled {
    opacity: 0.5;
  }
}
