@use "@causevest/ui-kit/styles";

.variants {
  background: styles.$color-bg-blue;
  padding: 40px 0;
  margin-top: 25px;

  &__title {
    color: styles.$color-black-6;
    font-size: 36px;
    font-weight: 300;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 38px;
  }

  &__left {
    width: 50%;
  }

  &__subtitle {
    margin-top: 14px;
    color: styles.$color-grey-2;
  }

  &__right {
    position: relative;
  }

  &__rightBg {
    &._desktop {
      display: block;
    }

    &._mobile {
      display: none;
    }
  }

  &__frame {
    position: absolute;
    top: 86px;
    left: 29px;
    width: 280px;
    height: 187px;

    &._active:before {
      content: "Community";
      position: absolute;
      z-index: 2;
      left: 0;
      top: 214px;
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: styles.$color-blue;
    }
  }

  &__pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      top: 214px;
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: styles.$color-blue;
    }

    &._firstPic {
      opacity: 1;

      &:before {
        content: "Rewards";
      }
    }

    &._secondPic {
      opacity: 1;

      &:before {
        content: "Validation";
      }
    }

    &._thirdPic {
      opacity: 1;

      &:before {
        content: "Audit";
      }
    }
  }

  &__badge {
    position: absolute;
    z-index: 1;
    cursor: pointer;

    img {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      img {
        margin-top: -8px;
        margin-left: -8px;
        width: 90px;
        height: 90px;
      }
    }

    &._first {
      top: 90px;
      left: -25px;
    }

    &._second {
      top: 310px;
      left: -25px;
    }

    &._third {
      top: 225px;
      left: 283px;
    }
  }
}

@media (max-width: styles.$sm) {
  .variants {
    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 14px;
    }

    &__inner {
      flex-direction: column;
      align-items: flex-start;
    }

    &__left {
      width: 100%;
    }

    &__right {
      position: relative;
      left: 30px;
      margin: 0 auto;
    }

    &__rightBg {
      &._desktop {
        display: none;
      }

      &._mobile {
        display: block;
      }
    }

    &__frame {
      top: 55px;
      left: 19px;
      width: 178px;
      height: 120px;

      &._active:before {
        top: 136px;
        font-size: 14px;
      }
    }

    &__badge {
      &._first {
        top: 54px;
        left: -32px;
      }

      &._second {
        top: 187px;
        left: -30px;
      }

      &._third {
        top: 118px;
        left: 176px;
      }
    }

    &__pic {
      &:before {
        top: 136px;
        font-size: 14px;
      }
    }
  }
}
