@use "@causevest/ui-kit/styles";

.cheader {
  position: relative;
  padding: 20px 0;

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(309deg, styles.$color-blue-2 -20.25%, styles.$color-blue-3 109.55%);
  }

  &__inner {
    position: relative;
    align-items: center;
    gap: 10px;
    z-index: 1;
  }

  &__avatar {
    position: relative;
    border-radius: 50%;
    border: 3px solid styles.$color-white;
  }

  &__badge {
    position: absolute;
    top: 0;
    right: 0;
    background: styles.$color-white;
    padding: 2px;
    border-radius: 50%;
    height: 22px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    color: styles.$color-white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
}

.wrapper {
  padding: 30px 0;
  overflow: hidden;
  position: relative;
}

.bg {
  width: 100%;
  height: 100%;
  filter: blur(220px);
}

.inner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  gap: 35px;
}

.left {
  position: relative;
  overflow: hidden;
  min-width: 520px;
  width: 520px;
  height: 520px;
}

.right {
  &._gap {
    gap: 20px;
  }

  &__top {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.id {
  @include styles.textEllipsis(50%);
  color: styles.$color-blue;
  font-size: 14px;
  font-weight: 500;
}

.report {
  color: styles.$color-grey-1;
  text-decoration: none;

  &__text {
    font-size: 12px;
    white-space: nowrap;
  }
}

.title {
  font-size: 28px;
  font-weight: 500;
  color: styles.$color-black-6;
}

.description {
  word-break: break-word;
  white-space: pre-line;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.daysLeft {
  text-align: right;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.feed {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px 0;
  gap: 30px;
}

.aside {
  gap: 30px;
  width: 250px;

  &__item {
    gap: 10px;
  }

  &__children {
    gap: 15px;
    p {
      font-size: 12px;
    }
  }

  &__footer {
    gap: 10px;

    > p {
      font-size: 12px;
      color: styles.$color-grey-1;

      > span {
        color: styles.$color-grey-7;
      }
    }
  }

  &__title {
    color: styles.$color-black-6;
    font-size: 22px;
    font-weight: 300;
    white-space: nowrap;
  }
}

.supportBtn {
  height: 40px;
}

.tier {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  &__image {
    width: 140px;
    height: 142px;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
  }

  &__description {
    @include styles.multilineEllipsis(3, 100%);
    font-size: 14px;
    text-align: center;
  }

  &__donateBtn {
    padding: 20px 25px;
    height: 28px;
  }
}

.supportersSlide {
  gap: 20px;
}

.mobileBadge {
  display: none;
}

@media (max-width: styles.$md) {
  .inner {
    flex-direction: column;
  }

  .left {
    display: none;
  }

  .mobileBadge {
    display: flex;
    margin-top: 20px;
  }
}

@media (max-width: styles.$sm) {
  .supportBtn {
    width: 100%;
  }

  .wrapper {
    padding: 15px 0 18px;
  }

  .right {
    width: 100%;

    &__top {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }

  .id {
    order: 2;
    @include styles.textEllipsis(100%);
  }

  .title {
    font-size: 24px;
  }

  .description {
    font-size: 14px;
  }

  .daysLeft {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .feed {
    flex-direction: column;
  }

  .aside {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;

    &__item {
      width: 47%;

      &:last-child {
        margin-top: -186px;
      }
    }
  }
}

@media (max-width: 548px) {
  .aside {
    &__item {
      width: 100%;

      &:last-child {
        margin-top: 0;
      }
    }
  }
}
