@use "@causevest/ui-kit/styles";

.cover {
  height: 250px;
  background: linear-gradient(309deg, styles.$color-blue-2 -20.25%, styles.$color-blue-3 109.55%);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 35px;
  gap: 30px;

  &._menuGap {
    margin-top: 82px;
  }
}

.aside {
  width: 220px;
  gap: 30px;

  &._mobile {
    display: none;
  }

  &._indented {
    margin: 74px 0 30px;
  }

  &._semiIndented {
    margin: 30px 0;
  }
}

.main {
  width: calc(100% - 250px);
  gap: 30px;
}

.center {
  flex-grow: 2;
  gap: 30px;
}

.messagesMobile {
  display: none;
}

.showOnMobile {
  display: none;
}

.hideOnMobile {
  display: flex;
  gap: 30px;
}

.previewBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 40px;
  color: styles.$color-blue;
  background: styles.$color-bg-blue;
  border: 1px solid
    linear-gradient(309deg, styles.$color-blue-2 -20.25%, styles.$color-blue-3 109.55%);

  &:hover {
    background: styles.$color-bg-blue;
  }
}

@media (max-width: styles.$md) {
  .messagesMobile {
    display: block;
  }

  .messagesDesktop {
    display: none;
  }
}

@media (max-width: 1220px) {
  .aside {
    &._mobile {
      display: flex;
    }

    &._desktop {
      display: none;
    }
  }
}

@media (max-width: styles.$sm) {
  .cover {
    height: 150px;
  }

  .container {
    gap: 20px;
  }

  .wrapper {
    flex-direction: column;
    margin: 0 0 20px;
    gap: 20px;

    &._editProfile {
      .aside._semiIndented {
        margin: 0;
        order: 2;
      }

      .center {
        order: 1;
        margin: 0;
      }
    }
  }

  .aside,
  .center {
    width: calc(100vw - 48px);
  }

  .aside {
    gap: 20px;

    &._dashboard {
      order: 2;
    }
  }

  .main {
    width: 100%;
    gap: 20px;
    order: 1;
  }

  .showOnMobile {
    display: flex;
    gap: 20px;
  }

  .hideOnMobile {
    display: none;
  }
}

@media (max-width: 450px) {
  .aside,
  .center {
    width: calc(100vw - 32px);
  }
}
