@use "@causevest/ui-kit/styles";

.userInfo {
  gap: 10px;

  &__item {
    color: styles.$color-black-6;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-decoration: none;
  }
}

