@use "@causevest/ui-kit/styles";

.wrapper {
  position: relative;
  font-size: 14px;
  line-height: 1;
  min-height: calc(100vh - 410px);
  margin-top: 70px;
}

.inner {
  margin: auto;
  align-items: center;
  background: styles.$color-white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 30px rgba(13, 58, 115, 0.1);
  text-align: center;
}

.icon {
  color: styles.$color-green;
  font-size: 50px;
}

.subtitle {
  color: styles.$color-grey-7;

  > a {
    text-decoration: none;
    color: styles.$color-blue;
    font-weight: 500;
  }
}
