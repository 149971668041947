@use "@causevest/ui-kit/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 15px 24px 24px;
  background: styles.$color-white;
  border-radius: 4px;
}

.title {
  font-size: 24px;
  color: styles.$color-grey-7;
  border-bottom: 1px solid styles.$color-grey-8;
  width: 100%;
  padding-bottom: 15px;
}

.inner {
  gap: 24px;
  margin-top: 24px;
}

.business {
  text-decoration: none;
  color: styles.$color-black;

  &__title {
    @include styles.textEllipsis(100%);
    font-size: 14px;
  }
}

.campaign {
  min-height: 220px !important;
  max-width: 265px;
}

@media (max-width: styles.$sm) {
  .campaign {
    max-width: 100%;
  }
}
