@use "@causevest/ui-kit/styles";

.tabs {
  background: styles.$color-white;

  &._fixed {
    position: fixed;
    top: 69px;
    left: 0;
    z-index: 101;
    border-top: 1px solid styles.$color-light-grey;
    border-bottom: 1px solid styles.$color-light-grey;
    width: 100%;
    border-radius: 0;

    .tabs__inner {
      padding-left: 25px;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &__wrapper {
    height: 48px;
    margin-bottom: 1px;
  }
}

@media (max-width: 1325px) {
  .tabs {
    &._fixed {
      max-width: 100%;
    }
  }
}

@media (max-width: 1216px) {
  .tabs {
    &._fixed {
      max-width: 100%;
    }
  }
}

@media (max-width: styles.$sm) {
  .tabs {
    width: 100%;

    &__wrapper {
      width: 100%;
    }

    &._fixed {
      .tabs__inner {
        padding-left: 17px;
        max-width: 100%;
      }
    }
  }
}
