.filtersWrapper {
  padding: 25px 0;
  border-top: 1px solid rgba(232, 231, 236, 0.3);
}

.cut {
  padding-top: 25px;

  &__inner {
    gap: 15px;
    flex-wrap: wrap;
  }
}

.filtersInner {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filters {
  gap: 15px;
}

.select {
  width: 180px;

  [role="combobox"] {
    padding: 4px 35px 4px 15px;
  }
}

.input {
  width: 276.5px;

  input {
    padding: 4px 15px;
  }
}

.btns {
  &._mobile {
    display: none;
  }
}

.mobileAside {
  display: none;
}

@media (max-width: 1100px) {
  .filtersInner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
  }

  .filters {
    flex-wrap: wrap;
  }
}

@media (max-width: 920px) {
  .filters {
    width: 100%;
  }

  .mobileAside {
    display: block;
  }
}

@media (max-width: 620px) {
  .filters > * {
    width: 100%;
  }

  .cut {
    padding-top: 15px;
  }

  .select,
  .input {
    width: 100%;
  }

  .input__wrapper {
    width: 100%;
  }

  .btns {
    &._desktop {
      display: none;
    }

    &._mobile {
      display: block;
      margin-top: 25px;
    }
  }

  .mobileAside {
    width: 100%;
  }
}
