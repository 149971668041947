@use "@causevest/ui-kit/styles";

.label {
  color: styles.$color-grey-7;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;

  &._centered {
    font-size: 20px;
    margin: 20px auto 15px;
    font-weight: 300;
  }
}

.chip {
  > label {
    padding: 3px 23px 3px 12px;
    border: 1px solid styles.$color-light-grey;
    border-radius: 4px;
    transition: border 0.2s linear;
    margin: 0;
  }

  &._active > label {
    border: 1px solid styles.$color-blue;
  }

  &__inner {
    white-space: nowrap;
  }
}
